import React, { useEffect, useState } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import LockIcon from '@mui/icons-material/Lock';
import images from 'src/config/images';
import { capitalizeFirstLetter } from 'src/utils/helper';
import moment from 'moment';
import { dateFormat } from 'src/config';

export const getStepTitle = (type, mode) => {
  switch (type) {
    case 'wait':
      return 'Wait period';

    case 'email':
      if (mode === 'automation') {
        return 'Automated email';
      }
      return 'Manual email';

    case 'selectEmailTemplate':
      return 'Select email template';

    case 'linkedinConnection':
      if (mode === 'automation') {
        return 'LinkedIn connection (Automated)';
      }
      return 'LinkedIn connection (Manual)';

    case 'linkedinViewProfile':
      if (mode === 'automation') {
        return 'LinkedIn view profile (Automated)';
      }
      return 'LinkedIn view profile (Manual)';

    case 'linkedinMail':
      if (mode === 'automation') {
        return 'LinkedIn inmail (Automated)';
      }
      return 'LinkedIn inmail (Manual)';

    case 'linkedinMessage':
      if (mode === 'automation') {
        return 'LinkedIn message (Automated)';
      }
      return 'LinkedIn message (Manual)';

    case 'linkedin':
      return 'LinkedIn';

    case 'call':
      return 'Call back';

    case 'general':
      return 'To do';

    case 'endsquence':
      return 'End sequence';
    case 'sms':
      return 'SMS';
    default:
      return '';
  }
};

export const getCardBgColor = (type) => {
  switch (type) {
    case 'wait':
      return '#ECEFF1';
      break;

    case 'email':
      return '#E3F2FD';
      break;

    case 'selectEmailTemplate':
      return '#FCE4EC';
      break;

    case 'linkedinConnection':
    case 'linkedinViewProfile':
    case 'linkedinMail':
    case 'linkedinMessage':
    case 'linkedin':
      return '#1976D214';
      break;

    case 'call':
      return '#E8F5E9';
      break;

    case 'general':
      return '#E0F2F1';
      break;

    case 'endsquence':
      return '#FEEBEE';
      break;

    case 'sms':
      return '#EDE7F6';

    default:
      return null;
      break;
  }
};

export const getStepIcon = (type) => {
  switch (type) {
    case 'wait':
      return <WatchLaterIcon style={{ fill: '#78909C', width: 24, height: 24 }} />;
      break;

    case 'email':
      return <EmailIcon style={{ color: '#42A5F5' }} />;
      break;

    case 'selectEmailTemplate':
      return <ContactMailIcon style={{ color: '#FFA726' }} />;
      break;

    case 'linkedinConnection':
    case 'linkedinViewProfile':
    case 'linkedinMail':
    case 'linkedinMessage':
    case 'linkedin':
      return <LinkedInIcon style={{ color: '#1282B2' }} />;
      break;

    case 'call':
      return <PhoneCallbackIcon style={{ color: '#66BB6A' }} />;
      break;

    case 'general':
      return <img src={images.sequence.AddTaskFilled} style={{ width: '24px' }} />;
      break;

    case 'endsquence':
      return <LockIcon style={{ color: '#EF5350' }} />;
      break;

    case 'sms':
      return <SmsIcon style={{ fill: '#00bcd4' }} />;

    default:
      return null;
      break;
  }
};

export const getEnrollmentStepStatus = (task) => {
  const due = moment(task.dueOn).format(dateFormat);

  switch (task.status) {
    case 'completed':
      return `Completed`;

    case 'pending':
      return `Due - ${due}`;

    case 'hold':
      return `Not yet scheduled`;

    default:
      return `${capitalizeFirstLetter(task.status)}`;
  }
};

export const getEnrollmentStatusLabel = (status) => {
  switch (status) {
    case 'outOfOffice':
      return 'Out Of Office';

    case 'unsubscribed':
      return 'Unsubscribed';

    case 'interested':
      return 'Interested';

    case 'notInterested':
      return 'Not Interested';

    case 'inProgress':
      return 'In Progress';

    case 'hold':
      return 'On Hold';

    case 'completed':
      return 'Completed';

    case 'failed':
      return 'Failed';

    case 'bounced':
      return 'Bounced';

    default:
      return capitalizeFirstLetter(status);
  }
};

export const getStatusIcon = (status, description) => {
  if (
    status?.toLowerCase() === 'completed' &&
    description?.toLowerCase()?.includes('contact enrolled in sequence')
  ) {
    return `${images.sequence.statusIcon.seqGray}`;
  } else {
    switch (status.toLowerCase()) {
      case 'enrolledresumed':
        return `${images.sequence.statusIcon.enrollResume}`;
      case 'outofoffice':
        return `${images.sequence.statusIcon.outofoffice}`;
      case 'email_open':
        return `${images.sequence.statusIcon.emailOpened}`;
      case 'email_sent':
        return `${images.sequence.statusIcon.replyReceived}`;
      case 'email_click':
        return `${images.sequence.statusIcon.replyReceived}`;
      case 'replied':
        return `${images.sequence.statusIcon.replyReceived}`;
      case 'completed - interested':
      case 'interested':
        return `${images.sequence.statusIcon.interested}`;
      case 'completed - not interested':
      case 'notinterested':
        return `${images.sequence.statusIcon.notInterested}`;
      case 'inprogress':
        return `${images.sequence.statusIcon.inProgress}`;
      case 'completed':
      case 'never':
      case 'never sequenced':
        return `${images.sequence.statusIcon.seqGray}`;
      case 'draft':
      case 'hold':
        return `${images.sequence.statusIcon.pauseSeq}`;
      case 'paused - replied':
        return `${images.sequence.statusIcon.replyReceived}`;
      case 'bounced':
      case 'failed':
      case 'error':
      case 'paused - error':
      case 'paused - bounced email':
        return `${images.sequence.statusIcon.emailBounced}`;
      case 'unsubscribed':
        return `${images.sequence.statusIcon.emailUnsubscribed}`;
      default:
        return `${images.sequence.statusIcon.seqGray}`;
    }
  }
};

export const getColorCode = (status) => {
  switch (status?.toLowerCase()) {
    case 'enrolledresumed':
    case 'outofoffice':
    case 'email_open':
    case 'email_sent':
    case 'email_click':
    case 'replied':
    case 'inprogress':
      return { color: '#4CAF50', bgColor: '#E5FFEE' };
    case 'interested':
    case 'completed':
    case 'completed - interested':
    case 'notInterested':
    case 'completed - not interested':
    case 'never':
    case 'never sequenced':
      return { color: '#656E83', bgColor: '#D3D3D3' };

    case 'draft':
    case 'hold':
      return { color: '#FFCC00', bgColor: '#F5EDCA' };

    case 'paused - replied':
      return { color: '#4CAF50', bgColor: '#E5FFEE' };

    case 'bounced':
    case 'failed':
    case 'error':
    case 'paused - error':
    case 'paused - bounced email':
    case 'unsubscribed':
      return { color: '#FF453A', bgColor: '#FADEDF' };
    default:
      return { color: 'rgba(0, 0, 0, 0.87)', bgColor: 'rgba(0, 0, 0, 0.08)' };
  }
};

// This regex matches any string that starts with '%', followed by one or more characters that are not '%', and ends with '%'.
export const replacementVarialbleREGX = /%[^%]+%/;
// This regex matches any string that starts with '##', followed by one or more characters that are not '##', and ends with '##'.
export const replacementVarialbleREGX2 = /##[^##]+##/;
// This regex matches any string that starts with '{{', followed by one or more characters that are not '}', and ends with '}}'.
export const replacementVarialbleREGX3 = /{{[^}]+}}/;

export const checkHasReplacementVariable = (value) => {
  return (
    replacementVarialbleREGX.test(value) ||
    replacementVarialbleREGX2.test(value) ||
    replacementVarialbleREGX3.test(value)
  );
};

export const useStepError = (task, data, contact) => {
  const [error, setError] = useState('');

  useEffect(() => {
    if (
      !checkHasReplacementVariable(data?.content || '') &&
      !checkHasReplacementVariable(data?.subject || '')
    ) {
      setError('');
    } else if (task?.errors?.[contact?.id]?.length) {
      setError(`Please remove ${task?.errors?.[contact?.id]?.join(', ')} to enable your sending.`);
    }
  }, [task, data, contact]);

  return error;
};
