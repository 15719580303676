import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Alert } from '@mui/material';
import { debounce } from 'lodash';
import { Form } from 'src/components/shared';

import * as enrolmentActions from '../../../actions/enrolmentActions';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';
import { checkHasReplacementVariable, useStepError } from '../../../utils/sequenceHelper';

const Email = React.memo(function Email({
  task,
  taskId,
  contact,
  formProps,
  formRef,
  assignedTo,
  data,
}) {
  const dispatch = useDispatch();
  const [editorRef, setEditorRef] = useState(null);

  const [loading, setLoading] = useState(false);
  const textEditorRef = useRef(null);
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {
      console.log(error);
    }
  };

  const debouncePopulateTemplate = useCallback(
    debounce(async (contactId, templateId, subject, content) => {
      setLoading(true);
      await populateTemplate(contactId, templateId, subject, content, assignedTo);
      setLoading(false);
    }, 1000),
    [editorRef],
  );

  const onChange = async (value, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const templateId = task?.template?.id || '';
      const isContent = type === 'content';

      debouncePopulateTemplate.cancel();

      const hasReplacementVariable = checkHasReplacementVariable(value);

      if (hasReplacementVariable) {
        debouncePopulateTemplate(
          contact.id,
          templateId,
          isContent ? data.subject : value,
          isContent ? value : data.content,
        );
      }

      if (isContent && hasReplacementVariable) {
        setTimeout(() => {
          moveCursorToEnd();
        }, 100);
      }
    }
  };

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  useEffect(() => {
    const templateId = task?.template?.id || '';
    let data = formRef.current.values;
    data = data[taskId];
    if (templateId && templateId !== '' && contact && contact.id && contact.id !== '') {
      populateTemplate(contact.id, templateId, data.subject, data.content);
    }
  }, [contact]);

  const errorString = useStepError(task, data, contact);

  const populateTemplate = useCallback(
    (contactId, templateId, subject, content) => {
      return new Promise((resolve, reject) => {
        dispatch(
          enrolmentActions.fetchPopulateEnqueueTemplate(
            taskId,
            contactId,
            templateId,
            subject,
            content,
            assignedTo,
            resolve,
            reject,
          ),
        );
      });
    },
    [dispatch, taskId, assignedTo],
  );

  return (
    <Box mb={2} mt={2}>
      {errorString && errorString !== '' && !loading ? (
        <Alert severity="error" sx={{ padding: '4px 12px' }}>
          {errorString}
        </Alert>
      ) : null}

      <Form.Field.Input
        variant="outlined"
        name={`${taskId}.subject`}
        label="Subject"
        fullWidth={true}
        onChange={(val) => onChange(val, 'subject')}
        showPicker={true}
        options={replacementVariables}
        optValue="value"
        optLabel="text"
        emojiPicker={true}
      />
      <Form.Field.TextEditor
        getChildEditorRef={getChildEditorRef}
        name={`${taskId}.content`}
        onChange={(val) => onChange(val, 'content')}
        ref={textEditorRef}
      />
      <Form.Field.File name={`${taskId}.attachments`} maxSize={8} />
    </Box>
  );
});

export default Email;
