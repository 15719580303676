import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Form } from 'src/components/shared';
import { debounce } from 'lodash';
import { getInputReplacementVariables } from 'src/modules/app/actions/appSelector';

import { fetchPopulateEnqueueTemplate } from '../../../actions/enrolmentActions';
import { replacementVarialbleREGX } from '../../../utils/sequenceHelper';

const General = React.memo(function General({ task, taskId, contact, formRef, assignedTo }) {
  const dispatch = useDispatch();
  const replacementVariables = useSelector((state) => getInputReplacementVariables(state));

  const [editorRef, setEditorRef] = useState(null);

  const moveCursorToEnd = () => {
    try {
      editorRef.current.selection.select(editorRef.current.getBody(), true);
      editorRef.current.selection.collapse(false);
      editorRef.current.focus();
    } catch (error) {}
  };

  useEffect(() => {
    if (contact && contact.id && contact.id !== '') {
      populateTemplate(contact.id, undefined, '', task?.description);
    }
  }, [contact]);

  const debouncePopulateTemplate = useCallback(
    debounce(async (contactId, templateId, subject, content) => {
      await populateTemplate(contactId, templateId, subject, content, assignedTo);
    }, 1000),
    [editorRef],
  );

  const onChange = async (val, type) => {
    if (formRef.current) {
      let data = formRef.current.values;
      data = data[taskId];
      const templateId = task?.template?.id || '';
      const isContent = type === 'content';

      debouncePopulateTemplate.cancel();

      const hasReplacementVariable = replacementVarialbleREGX.test(val);

      if (hasReplacementVariable) {
        debouncePopulateTemplate(
          contact.id,
          templateId,
          isContent ? data.subject : val,
          isContent ? val : data.content,
        );
      }

      if (isContent && hasReplacementVariable) {
        setTimeout(() => {
          moveCursorToEnd();
        }, 100);
      }
    }
  };

  const getChildEditorRef = (editorRef) => {
    if (editorRef && editorRef !== null) {
      setEditorRef(editorRef);
    }
  };

  const populateTemplate = (contactId, templateId, subject, content) => {
    return new Promise((resolve, reject) => {
      dispatch(
        fetchPopulateEnqueueTemplate(
          taskId,
          contactId,
          templateId,
          subject,
          content,
          assignedTo,
          resolve,
          reject,
        ),
      );
    });
  };

  return (
    <Box mb={2} mt={2}>
      <Form.Field.TextEditor
        name={`${taskId}.description`}
        onChange={(val) => onChange(val, 'content')}
        showFontStyle={false}
        showFontEditor={false}
        showAlignProps={false}
        showListProps={false}
        showAttachments={false}
        getChildEditorRef={getChildEditorRef}
      />
    </Box>
  );
});

export default General;
