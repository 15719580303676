import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PageTitle } from 'src/components/App';

import ContactDetailsView from './ContactDetailsView';
import {
  fetchContact,
  putContact,
  fetchContactTask,
  fetchContactTasks,
  fetchContactNote,
  fetchContactNotes,
  fetchSequenceEnrollment,
  clearContactData,
  fetchSequenceEnrollments,
} from '../../actions/contactActions';
import { toggleAddTaskForm } from 'src/modules/app/actions/appActions';
import { fetchIntegrations } from 'src/modules/admin/actions/adminActions';

export const ForgotPasswordContainer = (props) => {
  const [newContactId, setNewContactId] = useState(null);

  useEffect(() => {
    return () => {
      props.clearContactData();
    };
  }, []);

  useEffect(() => {
    const contactId = props.match.params.id;
    if (contactId && contactId !== newContactId) {
      setNewContactId(contactId);
      props.fetchContact(contactId);
      props.fetchContactTask(contactId);
      props.fetchContactTasks(contactId); // for serverside paging
      props.fetchContactNote(contactId);
      props.fetchContactNotes(contactId, { users_eq: null, type_eq: null }); // for serverside paging
      props.fetchSequenceEnrollments(contactId);
      props.fetchSequenceEnrollment(contactId);
      props.fetchIntegrations();
    }
  }, [props.match?.params?.id, newContactId]);
  return (
    <>
      <PageTitle title="Contact Details" />
      <ContactDetailsView {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  contact: state.contacts.contact,
  contactTask: state.contacts.contactTask,
  tenant: state.app.tenant,
  contactTasks: state.contacts.contactTasks,
  contactNote: state.contacts.contactNote,
  contactNotes: state.contacts.contactNotes,
  showTaskForm: state.app.showTaskForm,
  sequenceEnrollment: state.contacts.sequenceEnrollment,
  sequenceEnrollments: state.contacts.sequenceEnrollments,
  isLoading: state.contacts.loading.contact,
  isLoadingContactNotes: state.contacts.loading.contactNotes,
  isLoadingContactTasks: state.contacts.loading.contactTasks,
  isLoadingContactSequence: state.contacts.loading.sequenceEnrollments,
});

const mapDispatchToProps = (dispatch) => ({
  clearContactData: () => dispatch(clearContactData()),
  fetchContact: (contactId) => dispatch(fetchContact(contactId)),
  fetchContactTask: (contactId) => dispatch(fetchContactTask(contactId)),
  fetchContactTasks: (contactId, filter, paging) =>
    dispatch(fetchContactTasks(contactId, filter, paging)),
  fetchContactNote: (contactId, filter) => dispatch(fetchContactNote(contactId, filter)),
  fetchContactNotes: (contactId, filter, paging) =>
    dispatch(fetchContactNotes(contactId, filter, paging)),
  fetchSequenceEnrollments: (contactId, filter, paging) =>
    dispatch(fetchSequenceEnrollments(contactId, filter, paging)),
  fetchSequenceEnrollment: (contactId) => dispatch(fetchSequenceEnrollment(contactId)),
  fetchIntegrations: () => dispatch(fetchIntegrations()),
  toggleAddTaskForm: (task = {}) => dispatch(toggleAddTaskForm(task)),
  putContact: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(putContact(data, resolve, reject));
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
